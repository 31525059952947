<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';
import { useClaimFormStore } from '@/stores/useClaimFormStore';

const claimFormStore = useClaimFormStore();
const router = useRouter();

const airlineName = computed(() => {
  const airline = claimFormStore.formData?.airline;
  if (airline === '1') return 'Aer Lingus';
  if (airline === '2') return 'American Airlines';
  if (airline === '3') return 'British Airways';
  return 'United Airlines';
});

const flightNumberPrefix = computed(() => {
  const airline = claimFormStore.formData?.airline;
  if (airline === '1') return 'EI';
  if (airline === '2') return 'AA';
  if (airline === '3') return 'BA';
  return 'UA';
});
</script>

<template>
  <div class="wrapper">
    <h1>Thank you!</h1>
    <p>Your claim has now been submitted.</p>
    <div class="table">
      <h3>Claim Details</h3>

      <table>
        <tr>
          <td>AerClub Membership Number:</td>
          <td>{{ claimFormStore.formData?.memberNumber }}</td>
        </tr>
        <tr>
          <td>First Name:</td>
          <td>{{ claimFormStore.formData?.firstName }}</td>
        </tr>
        <tr>
          <td>Family Name:</td>
          <td>{{ claimFormStore.formData?.familyName }}</td>
        </tr>
        <tr>
          <td>Departure Date:</td>
          <td>
            {{
              format(
                new Date(claimFormStore.formData!.departureDate),
                'dd-MM-yyyy'
              )
            }}
          </td>
        </tr>
        <tr>
          <td>Airline:</td>
          <td>{{ airlineName }}</td>
        </tr>
        <tr>
          <td>Ticket Number:</td>
          <td>
            {{ claimFormStore.formData?.ticketNumberPrefix
            }}{{ claimFormStore.formData?.ticketNumber }}
          </td>
        </tr>
        <tr>
          <td>Flight Number:</td>
          <td>
            {{ claimFormStore.formData?.flightNumber }}
          </td>
        </tr>
        <tr v-if="claimFormStore.formData?.flightOrigin">
          <td>Origin:</td>
          <td>{{ claimFormStore.formData?.flightOrigin }}</td>
        </tr>
        <tr v-if="claimFormStore.formData?.flightDestination">
          <td>Destination:</td>
          <td>{{ claimFormStore.formData?.flightDestination }}</td>
        </tr>
        <tr v-if="claimFormStore.formData?.bookingClass">
          <td>Booking Class:</td>
          <td>{{ claimFormStore.formData?.bookingClass }}</td>
        </tr>
        <tr v-if="claimFormStore.formData?.cabinClass">
          <td>Cabin Class:</td>
          <td>{{ claimFormStore.formData?.cabinClass }}</td>
        </tr>
      </table>
    </div>
    <p>
      This will now be processed and provided all information received is
      correct your account will be awarded any applicable benefits automatically in the coming days. If
      you do not receive benefits to your account <strong>6 weeks</strong> after
      you have made your claim please contact us
      <a
        target="_blank"
        href="https://www.aerlingus.com/support/customer-care/requests-and-enquiries/upcoming-travel-enquiry/"
        >here</a
      >.
    </p>
    <p class="submit">
      <button @click="router.push('/#claimForm')">Submit another claim</button>
    </p>
    <p></p>

    <div class="info-message" role="alert">
      <div>
        <span role="presentation" class="uil-icons uil-icon-alarm"></span>
      </div>
      <div>
        <h4>Have you claimed for all flight segments?</h4>
        <span class="message"
          >Please complete an individual claim for each flight segment of your
          journey e.g. A return flight requires 2 claims to be submitted.</span
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
p.submit {
  text-align: right;
}

div.table {
  background: var(--cloud-white);
  border-radius: 8px;
  padding: 20px;
  margin: 32px 0;
}
td {
  padding-right: 10px;
}

/**
 * info message
 */
div.info-message .uil-icons {
  color: var(--warning);
  font-size: 2em;
  margin-right: 20px;
}

div.info-message h4 {
  margin: 0 0 5px 0;
}
div.info-message {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  vertical-align: middle;
  border: var(--warning) 1px solid;
  background-color: var(--warning5);
  padding: 16px;
  margin: 32px 0;
  border-radius: 8px;
}
</style>
