// src/validators/pps-form.validator.ts
import * as Yup from "yup";
var memberNumberPrefix = "308147";
var schema = Yup.object().shape({
  memberNumber: Yup.string().required(
    `AerClub Membership Numbers begin with ${memberNumberPrefix}. Enter the last 10 digits of you number, excluding ${memberNumberPrefix}`
  ).min(16, `Must be 10 digits of you number, excluding ${memberNumberPrefix}`).test(
    "memberNumber",
    `This is not a valid Membership Number. Enter the last 10 digits of you number, excluding ${memberNumberPrefix}`,
    function(value) {
      if (isLuhnValid(value))
        return true;
      else
        return false;
    }
  ),
  firstName: Yup.string().required("First Name should be between 2 and 25 letters").min(2, "Must be longer than 2 letters").max(25, "Cannot be longer than 25 letters"),
  familyName: Yup.string().required("Family Name should be between 2 and 25 letters").min(2, "Must be longer than 2 letters").max(25, "Cannot be longer than 25 letters"),
  departureDate: Yup.string().required("Departure Date must have been in the past 6 months").matches(
    /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
    "Departure must be a valid date in the format DD/MM/YYYY"
  ),
  airline: Yup.string().required("Please select airline"),
  ticketNumber: Yup.string().required(
    "Ticket details can be found on your flight confirmation email or physical boarding pass (this is not available on digital boarding passes). "
  ).when("airline", {
    is: (airline) => airline === "1",
    then: (schema2) => schema2.matches(
      /\b^053|^125|^016|^169/,
      "Ticket number starts with 016, 053, 125 or 169. If no matching prefix - the flight is not eligible for award in AerClub programme"
    )
  }).when("airline", {
    is: (airline) => airline === "3",
    then: (schema2) => schema2.matches(
      /^125|^053/,
      "Ticket number starts with 053 or 125. If no matching prefix - the flight is not eligible for award in AerClub programme"
    )
  }).when("airline", {
    is: (airline) => airline === "4",
    then: (schema2) => schema2.matches(
      /^016|^053/,
      "Ticket number starts with 016 or 053. If no matching prefix - the flight is not eligible for award in AerClub programme"
    )
  }).matches(/^[0-9]+$/, "Ticket number must be only digits").min(13, "Ticket number must be at least 13 digits").max(14, "Ticket number cannot be more than 14 digits"),
  flightNumber: Yup.string().required("Flight number is up to 4 digits").matches(
    /^(EI|BA|UA|AA)\d{1,4}$/,
    "Flight number is up to 4 digits e.g. EI176 - is the Aer Lingus flight number to Heathrow"
  ),
  flightOrigin: Yup.string().when("airline", {
    is: (airline) => airline === "2" || airline === "3" || airline === "4",
    then: (schema2) => schema2.required("Origin must be 3 characters").matches(/^[a-zA-Z]{3}$/, "Origin must be 3 uppercase characters")
  }),
  flightDestination: Yup.string().when("airline", {
    is: (airline) => airline === "2" || airline === "3" || airline === "4",
    then: (schema2) => schema2.required("Destination must be 3 characters").matches(/^[a-zA-Z]{3}$/, "Destination must be 3 uppercase characters")
  }),
  bookingClass: Yup.string().when("airline", {
    is: (airline) => airline === "2" || airline === "3" || airline === "4",
    then: (schema2) => schema2.required("Please select Booking Class")
  }),
  cabinClass: Yup.string().when("airline", {
    is: (airline) => airline === "2" || airline === "3" || airline === "4",
    then: (schema2) => schema2.required("Please select Cabin Class")
  })
});
var isLuhnValid = (input) => {
  if (/[^0-9-\s]+/.test(input))
    return false;
  let nCheck = 0;
  let nDigit = 0;
  let bEven = false;
  const value = input.replace(/\D/g, "");
  for (let n = value.length - 1; n >= 0; n -= 1) {
    const cDigit = value.charAt(n);
    nDigit = parseInt(cDigit, 10);
    if (bEven) {
      nDigit *= 2;
      if (nDigit > 9) {
        nDigit -= 9;
      }
    }
    nCheck += nDigit;
    bEven = !bEven;
  }
  return nCheck % 10 === 0;
};
var validationSchema = schema;
export {
  validationSchema
};
